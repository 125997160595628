<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form
        class="ant-advanced-search-form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="课程名称">
              <a-input placeholder="请输入" v-model="keyword.name" />
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <!-- 1 待支付 2 待发货3 待收货 4 待评价  5 已完成 6 已取消 7已退款） -->
        <span slot="learnTotalTime" slot-scope="learnTotalTime">
          {{ learnTotalTime == 0 ? 0 : $commen.secondToMinute(learnTotalTime) }}
        </span>
        <span slot="unRecordNum" slot-scope="unRecordNum">
          {{
            unRecordNum.studentNum -
              unRecordNum.learningUserCount -
              unRecordNum.finishUserCount >
            0
              ? unRecordNum.studentNum -
                unRecordNum.learningUserCount -
                unRecordNum.finishUserCount
              : 0
          }}
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="学习详情"
            @click="courseStudyDetail(action.id)"
            size="small"
            type="primary"
          />
        </span>
      </a-table>
    </div>
    <a-modal
      title="详情"
      v-model="studyDetail.visible"
      width="50%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <studyDetail @callbackMethod="closeModel" :id="studyDetail.id" />
    </a-modal>
  </div>
</template>
<script>
import studyDetail from "./courseStudentList.vue";
const columns = [
  {
    title: "序号",
    key: "index",
    width: "4%",
    align: "center",
    // ellipsis: true,
    customRender: (text, record, index) =>
      `${
        (_this.list.pagination.current - 1) * _this.list.pagination.pageSize +
        index +
        1
      }`,
  },
  {
    title: "课程名称",
    dataIndex: "name",
    key: "name",
    width: "20%",
    align: "center",
  },
  {
    title: "累计总学习时长",
    dataIndex: "learnTotalTime",
    width: "15%",
    key: "learnTotalTime",
    align: "center",
    scopedSlots: { customRender: "learnTotalTime" },
  },
  {
    title: "购买人数",
    dataIndex: "studentNum",
    width: "15%",
    align: "center",
  },
  {
    title: "已学完人数",
    dataIndex: "finishUserCount",
    width: "15%",
    key: "finishUserCount",
    align: "center",
  },
  {
    title: "学习中人数",
    dataIndex: "learningUserCount",
    width: "15%",
    key: "learningUserCount",
    align: "center",
  },
  {
    title: "未学习人数",
    width: "15%",
    key: "unRecordNum",
    align: "center",
    scopedSlots: { customRender: "unRecordNum" },
  },
  {
    title: "操作",
    width: "10%",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
let _this;
export default {
  name: "courseStatistics",
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      innerWidth: window.innerWidth,
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 263,
      },
      isloading: false,
      //查询条件
      keyword: {
        //搜索条件
        name: "",
      },
      rowSelection,
      expandedRowKeys: [],
      selectRows: [], //选中项编号
      studyDetail: {
        id: "",
        visible: false,
      },
      selectRowId: "",
    };
  },
  components: {
    studyDetail,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 263;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getCourseStatistic();
  },
  methods: {
    getCourseStatistic() {
      //获取列表
      _this.isloading = true;
      _this.$api.course
        .getCourseStatistic(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.keyword.name
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.pagination.total = Number(res.data.total);

            _this.list.datas = res.data.records;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
          _this.modalClose();
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.getCourseStatistic();
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.getCourseStatistic();
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    onDateChange(date, dateString) {
      _this.keyword.startTime = dateString[0];
      _this.keyword.end = dateString[1];
    },
    courseStudyDetail(id) {
      _this.studyDetail.visible = true;
      _this.studyDetail.id = id;
    },
    closeModel() {
      _this.studyDetail.visible = false;
    },
  },
};
</script>
<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.ant-table-selection-column {
  width: 50px !important;
}
</style>
