<template>
  <div>
    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="percent" slot-scope="percent"> {{ percent * 100 }}% </span>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "序号",
    key: "index",
    width: "10%",
    align: "center",
    // ellipsis: true,
    customRender: (text, record, index) =>
      `${
        (_this.list.pagination.current - 1) * _this.list.pagination.pageSize +
        index +
        1
      }`,
  },
  {
    title: "学员姓名",
    dataIndex: "fullName",
    key: "fullName",
    width: "45%",
    align: "center",
  },

  {
    title: "学习进度",
    dataIndex: "percent",
    width: "45%",
    key: "percent",
    align: "center",
    scopedSlots: { customRender: "percent" },
  },
];

let _this;
export default {
  name: "courseStatistics",
  props: {
    id: String,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      innerWidth: window.innerWidth,
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      isloading: false,
      //查询条件
      expandedRowKeys: [],
      selectRows: [], //选中项编号
    };
  },

  mounted: function () {
    _this = this;
    if (_this.id) {
      _this.getStudentCourseDetail();
    }
  },
  methods: {
    getStudentCourseDetail() {
      //获取列表
      _this.isloading = true;
      _this.$api.course
        .getStudentCourseDetail(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.id,
          ""
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.pagination.total = Number(res.data.total);
            _this.list.datas = res.data.records;
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
          _this.modalClose();
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.getStudentCourseDetail();
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.getStudentCourseDetail();
    },
  },
};
</script>
<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.ant-table-selection-column {
  width: 50px !important;
}
</style>
